.link-block {
  .image {
    img {
      width: 48px;
    }
  }

  &-link {
    a {
      font-weight: $font-weight-bold;
    }
  }

  &.has {
    &-top-separator {
      padding-top: 1rem;
      border-top: 1px solid $media-border-color;
    }

    &-bottom-separator {
      padding-bottom: 1rem;
      border-bottom: 1px solid $media-border-color;
    }

    &-top-bottom-separator {
      border-top: 1px solid $media-border-color;
      border-bottom: 1px solid $media-border-color;
    }
  }
}