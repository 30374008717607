.notification-box {
  position: fixed;
  bottom: 0;
  left: 2rem;
  padding: 2rem;
  max-width: 25vw;
  background-color: rgba($color-dark-shade-2, 0.9);
  z-index: 100;
  transform: translateY(100%);
  transition: transform 0.3s ease-in-out;

  &.is-active {
    transform: translateY(0);
  }

  .notification-box-title {
    position: relative;
    display: inline-block;
    color: $color-white;
    background-color: $color-black;
    font-size: 1.25rem;
    padding: 0 0.5rem;
    font-weight: $font-weight-bold;
  }

  .notification-box-headline {
    color: $color-white;
    font-weight: $font-weight-bold;
  }

  .notification-box-message {
    color: $color-white;
  }

  @include until($tablet) {
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
  }
}

.notification-bar {
  position: relative;
  background-color: $color-notification-bar;
  padding: 0.375rem;
  text-align: center;
  display: flex;

  @include until($desktop) {
    flex-wrap: wrap;
  }

  .notification-bar-image {
    width: 50%;
    margin: 0 auto;
    order: -1;

    @include from($desktop) {
      width: auto;
      flex-basis: 20%;
      position: relative;
      display: flex;
      align-content: center;
      justify-content: center;
      order: 0;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 10%;
        height: 80%;
        border-left: 1px solid $color-dark-shade-2;
      }

      img {
        width: 80%;
      }
    }
  }

  .notification-bar-message {
    color: $color-dark-shade-2;
    padding: 0.25rem 1rem;

    @include from ($desktop) {
      flex-basis: 80%;
    }

    > p {
      display: inline;
      font-weight: $font-weight-bold;
      font-size: 75%;
    }
  }

  @include from($desktop) {
    padding: 0 10%;
  }
}
