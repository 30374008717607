$nfx-accordion-border: 1px solid $color-light-shade-2 !default;
$nfx-accordion-title-padding: 0.75rem 3rem 0.75rem 0 !default;
$nfx-accordion-content-padding: 0.5rem 3rem 0 1.25rem !default;
$nfx-accordion-icon-padding: 3rem !default;
$nfx-accordion-transition-duration: 0.25s !default;

.accordion {
  border-bottom: $nfx-accordion-border;
  text-align: left;

  div.accordion-item {
    &:first-child {
      .accordion-title {
        border-top: 3px solid $color-primary;
      }
    }
  }

  .content {
    a {
      font-weight: $font-weight-bold;
      text-decoration: underline;
      &:hover {
        color: $color-primary;
      }
    }
  }

  .accordion-title {
    position: relative;
    display: block;
    padding: $nfx-accordion-title-padding;
    cursor: pointer;
    border-top: $nfx-accordion-border;

    &::after {
      position: absolute;
      content: '';
      width: 1.25rem;
      top: 0.75rem;
      left: 0;
      text-align: center;
      transform: translateY(-50%);
    }

    &.has-icon {
      padding-left: $nfx-accordion-icon-padding;

      &::before {
        position: absolute;
        content: '';
        width: 2rem;
        height: 100%;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        background: center center / auto 2rem no-repeat;
      }

      + .accordion-content {
        padding-left: $nfx-accordion-icon-padding;
      }
    }

    &::after {
      left: auto;
      right: 0.25rem;
      content: '+';
      font-size: 2rem;
    }

    &.is-active {
      font-weight: $font-weight-bold;

      &::after {
        content: '-';
      }

      + .accordion-content {
        padding-top: 0.75rem;
        background: $color-light-shade-1;
        max-height: 70rem;

        @include until($desktop) {
          max-height: 120rem;
        }
      }
    }
  }

  .accordion-content {
    max-height: 0;
    overflow: hidden;
    padding: $nfx-accordion-content-padding;
    padding-top: 0;
    transition: max-height $nfx-accordion-transition-duration ease-in-out, padding-top $nfx-accordion-transition-duration ease-in-out;

    p {
      font-weight: $font-weight-light;
    }

    :last-child {
      margin-bottom: 1rem;
    }

    ol {
      margin-left: 0;
      li {
        position: relative;
        counter-increment: list-counter;
        padding: 0.5rem 0 0.5rem 2rem;
        border-top: 1px solid $color-light-shade-5;

        &:last-child {
          border-bottom: 1px solid $color-light-shade-5;
        }

        &::before {
          position: absolute;
          display: inline-block;
          width: 1.5rem;
          height: 1.5rem;
          content: counter(list-counter) " ";
          color: $color-white;
          background-color: $color-primary;
          border-radius: 50%;
          text-align: center;
          font-weight: $font-weight-bold;
          flex-shrink: 0;
          margin-right: 1rem;
          left: 0;
        }

        &::marker {
          content: '';
        }
      }
    }
  }
}

.faq-detail {
  .heading-2 {
    color: $color-primary;
  }

  strong {
    color: $color-primary;
  }
}
