//color palette
$color-white: #fff;
$color-black: #000;

$color-primary: #f7ff14;
$color-secondary: #f7ff14;
$color-tertiary: #f7ff14;
$primary: $color-primary;

$color-dark-shade-1: #3a3a3a;
$color-dark-shade-2: #000;
$color-dark-shade-3: #000;
$color-dark-shade-4: #000;

$color-light-shade-1: #f3f3f3;
$color-light-shade-2: #efefef;
$color-light-shade-3: #ccc;
$color-light-shade-4: #888;


// Typography
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;
$font-weight-headlines: $font-weight-regular;

$link-hover-color: $color-light-shade-4;

$font-primary: OpelNext, sans-serif;
$body-family: $font-primary;

$nfx-typography-body-size: (
  min: 18px,
  max: 18px,
);

$nfx-typography-heading-sizes: (
  1: (
    min: 25px,
    max: 36px,
  ),
  2: (
    min: 28px,
    max: 28px,
  ),
  3: (
    min: 24px,
    max: 24px,
  ),
  4: (
    min: 20px,
    max: 20px,
  ),
  5: (
    min: 18px,
    max: 18px,
  ),
);

// Icons
$fa-font-path: '/fonts/font-awesome';
$fa-li-margin: 1.75rem;

// Global
$body-color: $color-black;

// Breadcrumb
$breadcrumb-item-padding-horizontal: 0;
$breadcrumb-item-color: $color-black;
$breadcrumb-seperator-color: $color-primary;
$breadcrumb-seperator-transform: rotate(34deg);

// navbar
$navbar-item-underline-color: $color-primary;
$navbar-background-color: $color-white;
$navbar-padding: 0;

// interst-box
$interest-box-background-color: $color-black;

// static interest box
$static-interest-box-background-color: $color-black;
$static-interest-box-color: $color-black;


$static-interest-box-v2-background-color: $color-primary;
$static-interest-box-v2-color: $color-black;

//slider
$slider-tab-background-color: $color-light-shade-2;
$slider-tab-color: $color-black;

// Button
$button-primary-color: $color-black;
$button-primary-after-content: '\f054';

$button-secondary-background: $color-black;
$button-secondary-color: $color-white;
$button-secondary-after-color: $color-primary;

// content-tabs
$content-tab-background-color: $color-black;
$content-tab-color: $color-white;
$content-tab-hover-background-color: $color-primary;
$content-tab-hover-color: $color-black;
$content-tab-active-color: $color-black;
$content-tab-active-background-color: $color-white;
$content-tab-active-hover-background-color: $color-white;

// Hero
$hero-body-padding: 3rem 3rem;
$hero-body-padding-small: 3rem 3rem;
$hero-body-padding-tablet: 3rem 3rem;
$hero-body-padding-medium: 3rem 3rem;
$hero-body-padding-large: 3rem 3rem;

// Text-Flow
$nfx-text-flow-sizes: (
  text-flow-none: 0,
  text-flow-small: 0.5rem,
  text-flow-relative: 1em,
  text-flow-large: 2rem,
) !default;

//Footer
$footer-border-color: $color-dark-shade-1;
$footer-background-color: $color-black;
$footer-color: $color-light-shade-1;
