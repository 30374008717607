.contact-box {
  .box {
    background-color: $color-light-shade-1;
    padding: 2.5rem 2rem;
  }

  .heading-1 {
    font-size: 2.2rem;
  }

  textarea {
    height: auto;
  }

  .brand-search {
    height: 2.5em !important;
  }
}

@include until($tablet) {
  .contact-box {
    .js-brand-search-submit {
      height: 100%;
      font-size: 0.75rem;
    }
  }
}