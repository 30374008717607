.teaser {
  &.is-headline-link,
  &.is-text-link {
    padding: 1.25rem;
    background-color: $color-primary;
    color: $color-white;
    height: 100%;

    .heading-4 {
      color: $color-white;
    }

    .teaser-content {
      height: 100%;
      display: flex;
      flex-direction: column;

      *:nth-last-child(2) {
        flex-grow: 1;
      }
    }
  }

  &.is-same-height {
    .teaser-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &.is-image-text {
    height: 100%;
    display: flex;
    flex-direction: column;

    &.is-left-right {
      .teaser-visual {
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .teaser-content {
        padding: 2rem;
      }
    }

    .teaser-content {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      background-color: $color-white;
      padding: 1.5rem 1rem 1rem 1rem;
      font-weight: $font-weight-regular;

      .button-container {
        margin-top: auto;
      }

      .button {
        margin-top: 1rem;
      }
    }
  }

  &.is-icon-text {
    .teaser-content {
      padding-top: 1rem;
    }
  }

  &-highlighted-headline {
    position: relative;
    width: 100%;
    aspect-ratio: 1 / 1;
    border: 3px solid $color-primary;
    border-top: none;
    border-radius: 50%;

    & > * {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-small-image {
    width: 200px;
    height: 200px;
    margin: auto;
    object-fit: cover;
    img {
      object-fit: cover;
    }
  }

  &-fullwidth-fix-height {
    width: 100%;
    height: 160px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}

.teaser-visual {
  height: 100%;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}
