.content-tab.tabs.is-boxed {
  li {
    &:not(.is-active) {
      a {
        &:hover {
          color: $color-white;
        }
      }
    }
  }
}