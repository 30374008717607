.insurance-table {
  .columns {
    margin: 0;
  }

  .fa {
    &-check {
      color: $color-secondary;
    }

    &-close {
      color: $color-red;
    }
  }

  .head-row {
    width: 100%;

    .column {
      font-weight: $font-weight-bold;
      border-bottom: 1px solid $color-white;
      border-left: 1px solid $color-white;

      &:not(:first-child) {
        background-color: $color-light-shade-1;
      }

      &.is-highlight {
        background-color: $color-primary;
        color: $color-white;
      }
    }
  }

  .row {
    width: 100%;

    &:not(.description-row) {
      .columns {
        .column {
          &:first-child {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;
            background-color: $color-light-shade-1;
            font-size: 0.9rem;
            font-weight: $font-weight-regular;
            line-height: 1.2;
          }

          border-bottom: 1px solid $color-white;
          border-left: 1px solid $color-white;
        }

        &:hover {
          cursor: pointer;

          .column {
            background-color: $color-primary !important;
            color: $color-white !important;
          }
        }
      }
    }
  }

  .description-row {
    .column {
      display: flex;
      flex-direction: row;
      gap: 12px;
      padding-left: 1.2rem;
      padding-right: 1.2rem;

      .fas {
        margin-top: 4px;
      }

      .wysiwyg {
        line-height: 1.3;
      }
    }
  }
}

@include until($tablet) {
  .insurance-table {
    .row,
    .head-row {
      .columns {
        display: flex;
      }
    }

    .head-row {

      .column {
        font-size: 0.8rem;
        line-height: 1.3;
      }
    }

    .row {
      &:not(.description-row) {
        .columns {
          .column {
            &:first-child {
              font-size: 0.7rem;
            }
          }
        }
      }
    }
  }
}