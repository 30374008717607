::selection {
  background-color: $color-primary;
}

::-moz-selection {
  background-color: $color-primary;
}

body {
  font-weight: $font-weight-light;
  font-size: 18px;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased; // sass-lint:disable-line no-misspelled-properties
  text-rendering: optimizeLegibility;
}

@each $level, $sizes in $nfx-typography-heading-sizes {
  .heading-#{$level} {
    line-height: 1;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
  }
}

