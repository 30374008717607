.content-tab.tabs.is-boxed {
  @include from($tablet) {
    margin-top: -38px;
    margin-top: -46px;
  }

  ul {
    column-gap: 0.5rem;
  }

  li {
    background-color: $content-tab-background-color;

    a {
      color: $content-tab-color;
      text-transform: uppercase;
      font-weight: $font-weight-bold;

      &:hover {
        border-radius: unset;
        background-color: $content-tab-hover-background-color;
        color: $content-tab-hover-color;
        border-bottom-color: transparent;
      }
    }

    &.is-active {
      background-color: $content-tab-active-background-color;

      a {
        color: $content-tab-active-color;

        &:hover {
          background-color: $content-tab-active-hover-background-color;
        }
      }
    }
  }
}

.tab-text {
  @include until($tablet) {
    display: none;
  }
}

.tab-icon {
  @include from($tablet) {
    display: none;
  }
}

.tab-main-content {
  display: none;

  &.is-active {
    display: block;
  }

  &.editmode {
    display: block;
    min-height: 300px;
  }
}
