.interest-box {
  position: relative;
  padding: 1.25rem;
  background-color: $interest-box-background-color;
  overflow: hidden;

  &-product-info {
    z-index: 2;
    position: relative;
  }

  &-rate {
    padding-right: 0.5rem;

    &-container {
      @include font-scale(70px, 88px);
      display: flex;
      align-items: center;
      z-index: 0;
      line-height: 1;
      position: absolute;
      right: 1.25rem;
      bottom: 1.25rem;
    }

    &-after {
      @include font-scale(15px, 20px);
    }

    &-onhover {
      @include font-scale(40px, 40px);
      visibility: hidden;
      z-index: 2;
      position: relative;
    }
  }

  &-cta {
    display: none;
    z-index: 3;
    position: absolute;
    right: 1.25rem;
    bottom: 1.25rem;
  }

  &::before {
    display: block;
    -webkit-transform: rotate(29deg);
    transform: translateY(-50%) rotate(29deg);
    background: $color-primary;
    color: #000;
    content: '';
    width: 120%;
    aspect-ratio: 1/1;
    position: absolute;
    left: -80%;
    z-index: 1;
    pointer-events: none;
    -webkit-transition: all 0.2s ease-out 0s;
    -moz-transition: all 0.2s ease-out 0s;
    -o-transition: all 0.2s ease-out 0s;
    transition: all 0.2s ease-out 0s;
  }

  &:hover {
    &::before {
      left: 0;
    }

    .interest-box {
      &-cta {
        display: block;
      }

      &-rate-onhover {
        visibility: visible;
      }
    }
  }

  &.is-editmode {
    background-color: $color-primary;

    &::before {
      left: 0;
    }

    .interest-box {
      &-cta {
        display: block;
        position: relative;
      }

      &-rate-onhover {
        visibility: visible;
      }

      &-rate-container {
        display: none;
      }
    }
  }
}

.static-interest-box {
  display: inline-block;
  background-color: $static-interest-box-background-color;
  color: $static-interest-box-color;
  padding: 1rem;

  &.is-version-2 {
    background-color: $static-interest-box-v2-background-color;
    color: $static-interest-box-v2-color;

    .percent-sign {
      font-size: 2rem;
    }
  }

  .interest-box-rate-container {
    position: static;
  }

  .interest-box-rate {
    line-height: 0.8;
  }
}
