.footer {
  background-color: $footer-background-color;
  color: $footer-color;

  &-links {
    padding: 1.5rem;
    border-bottom: 1px solid $footer-border-color;
  }

  &-link {
    text-decoration: none;
    color: $color-light-shade-1;
    font-size: 0.9375rem;
  }

  &-search {
    padding: 1rem;
    border-bottom: 1px solid $footer-border-color;
  }

  &-action {
    padding: 1.5rem;
    border-bottom: 1px solid $footer-border-color;

    &-icon {
      display: block;
      text-decoration: none;
      color: $color-light-shade-1;
      font-size: 4rem;

      &:hover {
        color: $color-primary;
      }
    }

    &-link {
      text-decoration: none;
      color: $color-light-shade-1;
      transition: color 0.25s ease-in-out;

      &::after {
        content: '\f054';
        font-family: 'Font Awesome 6 Free';
        text-rendering: auto;
        font-size: 0.5em;
        color: $color-primary;
        margin: 0 0.2rem;
        position: relative;
        bottom: 2px;
      }

      &:hover {
        transition: color 0.25s ease-in-out;
        color: $color-primary;
      }
    }
  }

  &-online-safety {
    padding: 2rem 1.5rem;
  }

  &-legal {
    display: flex;
    column-gap: 0.5rem;
    padding: 1rem;

    a {
      text-decoration: none;
    }

    .slash-before-tablet {
      margin-top: -3px;
    }
  }

  .is-uppercase {
    text-transform: none !important;
    font-weight: $font-weight-regular;
  }
}

@include from($tablet) {
  .footer-link::before {
    font-size: 1.5rem;
  }

  .container.is-sticky {
    .footer {
      transition: opacity 0.25s ease;
      opacity: 0.7;
    }
  }
}

@include until($tablet) {
  .footer-legal {
    flex-direction: column;
    align-items: center;
  }
}
