//color palette
$color-white: #fff;
$color-black: #000;
$color-red: #fa4616;

$color-primary: #243782;
$color-secondary: #43aaa0;
$color-secondary-light: #a0d4cd;
$color-tertiary: #243782;
$color-notification-bar: $color-secondary-light; //@colorMintLight
$primary: $color-primary;

$color-anthracite: #1d252d;

$color-dark-shade-1: #404352;
$color-dark-shade-2: $color-anthracite; //@colorAnthracite
$color-dark-shade-3: #000;
$color-dark-shade-4: #000;
$color-dark-shade-5: #1e2336;

$color-light-shade-1: #e1e6f7;
$color-light-shade-2: #efefef;
$color-light-shade-3: #ccc;
$color-light-shade-4: #888;
$color-light-shade-5: #cdd5f1;
$color-light-shade-6: #f0f0f0;
$color-light-shade-7: #f5f7fc;


// Typography
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-bold: 700;

$link-hover-color: $color-light-shade-4;

$font-primary: 'Encode Sans', sans-serif;
$body-family: $font-primary;

$nfx-typography-body-size: (
  min: 18px,
  max: 18px,
);

$nfx-typography-heading-sizes: (
  1: (
    min: 25px,
    max: 46px,
  ),
  2: (
    min: 28px,
    max: 28px,
  ),
  3: (
    min: 24px,
    max: 24px,
  ),
  4: (
    min: 20px,
    max: 20px,
  ),
  5: (
    min: 18px,
    max: 18px,
  ),
);

// Icons
$fa-font-path: '/fonts/font-awesome';
$fa-li-margin: 1.75rem;

// Global
$body-color: $color-black;

// Breadcrumb
$breadcrumb-item-padding-horizontal: 0;
$breadcrumb-item-color: $color-white;
$breadcrumb-seperator-color: $color-white;
$breadcrumb-seperator-transform: none;

// navbar
$navbar-item-underline-color: $color-secondary;
$navbar-background-color: $color-primary;
$navbar-padding: 1rem 0;

// interst-box
$interest-box-background-color: $color-black;

// static interest box
$static-interest-box-background-color: $color-black;
$static-interest-box-color: $color-black;


$static-interest-box-v2-background-color: $color-primary;
$static-interest-box-v2-color: $color-black;

//slider
$slider-tab-background-color: $color-anthracite;
$slider-tab-color: $color-white;

// Button
$button-primary-color: $color-black;
$button-primary-after-content: none;

$button-secondary-background: transparent;
$button-secondary-color: $color-primary;
$button-secondary-after-color: $color-primary;

// content-tabs
$content-tab-background-color: $color-black;
$content-tab-color: $color-white;
$content-tab-hover-background-color: $color-primary;
$content-tab-hover-color: $color-black;
$content-tab-active-color: $color-black;
$content-tab-active-background-color: $color-white;
$content-tab-active-hover-background-color: $color-white;

// Hero
$hero-body-padding: 3rem 3rem;
$hero-body-padding-small: 3rem 3rem;
$hero-body-padding-tablet: 3rem 3rem;
$hero-body-padding-medium: 3rem 3rem;
$hero-body-padding-large: 3rem 3rem;

// Text-Flow
$nfx-text-flow-sizes: (
  text-flow-none: 0,
  text-flow-small: 0.5rem,
  text-flow-relative: 1em,
  text-flow-large: 2rem,
) !default;

//Footer
$footer-border-color: $color-dark-shade-1;
$footer-background-color: $color-dark-shade-2;
$footer-color: $color-white;

// Icon
$icon-dimensions-large: 6.5rem;

// Slider Rate Box
$slider-rate-box-font-size: 1.444rem;
$slider-mobile-rate-box-font-size: 1rem;
