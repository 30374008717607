.pagination {
  &-link {
    background-color: $color-anthracite;
    color: $color-white;
    font-weight: $font-weight-regular;

    &:hover,
    &:focus {
      background-color: $color-primary;
      color: $color-white;
    }

    &.is-current {
      background-color: $color-primary;
      pointer-events: none;
    }
  }

  &-item {
    &.active {
      background-color: $color-primary;
    }
  }
}