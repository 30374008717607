.hero-slim {
  position: relative;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height:100%;
    object-fit: cover;
    z-index: 0;

    @include until($desktop) {
      object-position: top right;
    }
  }
  .columns {
    position: relative;
    min-height: 315px;
    z-index: 1;
  }

  .button {
    &:hover, &:focus {
      color: $color-secondary !important;
      border-color: $color-secondary !important;
      background-color: $color-white !important;
    }
  }

  .heading-1 {
    color: $color-primary;
    font-weight: $font-weight-bold;
  }

  .column {
    color: $color-dark-shade-2;

    &.has-background-box {
      background-color: rgba($color-dark-shade-2, 0.8);

      .heading-1 {
        background: none !important;
        color: $color-white;
      }
    }
  }

  &.has-transition-pattern {
    margin-bottom: 75px;
    &:after {
      content: url("../images/mint-punkte.svg");
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translateX(-50%);
      display: inline-block;
      width: clamp(200px, 100%, 400px);
      height: 75px;
      margin-bottom: -64px;
    }
  }

  &.is-stellantis {
    h1,h2,h3,h4,h5,h6 {
      @extend .has-dark-text;

      strong,b {
        @extend .has-primary-text;
      }
    }

    .is-link {
      &.button {
        &:hover, &:focus {
          color: $color-primary !important;
          border-color: unset !important;
          background-color: unset !important;
        }
      }
    }
  }

  .fa-ul {
    li {
      font-weight: $font-weight-regular;
    }
  }

  .text-flow {
    & > *:not(.no-flow) + * {
      margin-top: 0.5rem;
    }
  }
}
