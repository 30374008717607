.hero {
  .hero-body {
    align-items: flex-end;
  }

  .hero-headline {
    mix-blend-mode: hard-light;
    background: $color-anthracite;
    padding: 2rem 3rem;
    margin-bottom: 1.5rem;
  }

  .heading-1 {
    font-weight: $font-weight-headlines;
    color: $color-white;

    &::after {
      display: none;
    }
  }
}
