@each $level, $sizes in $nfx-typography-heading-sizes {
  .heading-#{$level} {
    line-height: 1.2;
    font-weight: $font-weight-headlines;
    color: $color-primary;
    text-transform: none;
  }
}

::selection {
  background-color: $color-primary;
  color: $color-white;
}

strong {
  color: $color-primary;
}

