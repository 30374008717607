.brand-banner {
  display: flex;
  justify-content: center;
  padding: 1rem 0;
  flex-wrap: wrap;
  column-gap: 0.75rem;
  row-gap: 1.5rem;
  max-width: 1100px;
  margin: 0 auto;

  .icon {
    filter: grayscale(1);
  }

  .icon-text {
    padding: 0rem 0.2rem;
    align-items: center;
    justify-content: center;
    column-gap: 0.5rem;
    opacity: 0.7;
    transition: opacity 0.3s ease-out;

    &:hover {
      opacity: 1;

      .icon {
        transition: filter 0.25s ease-in-out;
        filter: none;
      }
    }
  }
}
