.navbar {
  background-color: $color-primary;

  .logo {
    height: 3rem;
    max-height: none;
    width: 100%;
    padding-bottom: 0.25rem;
    margin-right: 1rem;

    @include from ($desktop) {
      height: auto;
      max-height: none;
      width: 266px;
      padding: 14px 20px;
      margin-right: 1rem;
    }
  }

  .button {
    &.is-primary {
      background-color: $color-primary;
      border-color: $color-white;
      color: $color-white;
      transition: all 0.3s ease-out;

      &:hover {
        background-color: $color-white;
        color: $color-primary;
      }
    }
  }

  &-submenu {
    &-link {
      font-weight: $font-weight-regular;

      &:hover,
      &:focus {
        color: inherit;
      }
    }
  }
}

.navbar-brand {
  padding-left: 1.25rem;

  @include from($desktop) {
    margin-right: 3rem;
  }
}

.navbar-start {
  @include from($desktop) {
    margin-left: 2.5rem;
  }
}

.navbar-end {
  padding-right: 1.5rem;

  @include from($desktop) {
    align-items: center;
  }

  .button {
    text-transform: none;
  }
}

.navbar-menu {
  background-color: unset;
}

.navbar-burger {
  color: $color-white;
  display: block;
  margin-left: auto;

  top: 50%;
  position: absolute;
  right: 1rem;
  transform: translateY(-50%);

  @include from($desktop) {
    display: none;
  }
}

.navbar-submenu-title {
  clip-path: none;
  color: $color-white;
  background-color: $color-dark-shade-2;
}

.navbar-item {
  &.is-link {
    position: relative;

    &::after {
      margin-left: 0.5rem;
      display: inline-block;
      content: "\f35d";
      font-family: 'Font Awesome 6 Free';
      font-weight: 600;
    }
  }
}

a.navbar-item.is-active, .navbar-link.is-active {
  color: $color-white;
}


a.navbar-item:focus, a.navbar-item:focus-within, a.navbar-item:hover, a.navbar-item.is-active, .navbar-link:focus, .navbar-link:focus-within, .navbar-link:hover, .navbar-link.is-active {
  background-color: unset;
  color: $color-white;
}
