.breadcrumb {
  font-weight: $font-weight-bold;
  text-transform: uppercase;

  ul {
    align-items: center;
  }

  li {
    + li {
      &::before {
        color: $breadcrumb-seperator-color;
        content: '|';
        display: inline-block;
        padding: 0 0.5rem;
        transform: $breadcrumb-seperator-transform;
        font-weight: $font-weight-bold;
      }
    }
  }
}
