.box {
  padding: 1.25rem;

  &.has-box-shadow {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  &-with-plus-icon {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 14px;

    icon {
      font-size: 1.5em;
      margin-top: 2px;
    }
  }
}
