.swiper-slide {
  height: 700px;

  &.height-auto {
    height: auto;
  }
}

.slider-image-wrapper {
  .slider-content {
    padding: 2.222rem 2.222rem 3.333rem;
    color: $color-white;

    h1,h2,h3,h4,h5,h6,
    [class^="heading-"] {
      color: $color-white;
      text-transform: uppercase;
    }
  }
}


.slider-content {
  &.is-stellantis-slider {
    margin-top: 3%;
    padding-bottom: 8%;

    h1 {
      font-size: 3.333rem;
      font-weight: 500;
      letter-spacing: 5px;
      line-height: 1.2;
      margin-top: 0.5rem;
    }

    h2,h3,h4,h5,h6 {
      letter-spacing: 2px;
    }

    .button-container {
      margin-top: 1.5625rem;
    }

    .button {
      &.is-primary {
        background-color: rgba($color-white, 0.8);

        &:hover,
        &:focus {
          background-color: $color-white;
          border-color: $color-white;
          color: $color-primary;
        }
      }
    }

    .footnote {
      font-size: 11px;
      font-weight: 500;
    }

    .wysiwyg {
      [class^="heading"] {
        &:not(:first-child) {
          margin-top: 0;
        }
      }
    }
  }

  &.is-mint-schema {
    .fa-check {
      &:before {
        content: "";
        position: absolute;
        background: url("../images/mint-checks.svg");
        background-size: cover;
        top: 4px;
        left: 0;
        width: 22px;
        height: 22px;
      }
    }

    .fa-ul {
      margin-top: 2.77rem;
      margin-left: 2rem;
      font-size: 1.444rem;
    }

    .fa-li {
      top: 4px;
      left: -2rem;
    }
  }

  &.head-monthrate-layout {
    .button-container {
      margin-top: 0.5rem;
    }
  }
}

@include between($tablet, $desktop) {
  .slider-tab-container {
    font-size: 0.777rem;
  }
}

@include until($tablet) {
  .swiper-slide {
    height: 570px;
  }

  .slider-image-wrapper {
    .slider-content {
      padding-left: 1.111rem;
      padding-right: 1.111rem;
    }
  }

  .slider-content {
    &.is-stellantis-slider {
      h1 {
        font-size: 1.444rem;
      }

      h2 {
        font-size: 1rem;
      }

      h4 {
        font-size: 0.888rem;
      }

      .fa-ul {
        font-size: 1rem;
        margin-bottom: 1rem;
      }

      .button-container {
        margin-top: 1rem;
        margin-bottom: 1.111rem;
      }
    }

    &.head-monthrate-layout {
      h1 {
        font-size: 2.222rem;
      }
    }

    &.head-list-layout {
      .fa {
        &-ul {
          text-align: left;

          li {
            margin-top: 0.5rem;
          }
        }

        &-li {
          top: 0;
        }
      }
    }
  }
}
