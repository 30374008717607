.column {
  &-seperation-line-desktop {
    @include from($tablet) {
      border-right: 1px solid $color-light-shade-3;
    }
  }
}

.columns {
  &-gapless-mobile {
    div:nth-child(1) {
      @include until($tablet) {
        padding-bottom: 0;
      }
    }

    div:nth-child(2) {
      @include until($tablet) {
        padding-top: 0;
      }
    }
  }
}
