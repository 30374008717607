.is-vertical-end {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.is-vertical-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.image-h-centered img {
  text-align: center;
}

.is-inline-block {
  display: inline-block;
}

.sticky {
  &-bottom-tablet {
    @include from($tablet) {
      position: sticky;
      bottom: 0;
      z-index: 5;
    }
  }

  &-top {
    position: sticky;
    top: 0;
    z-index: 5;
  }
}

.is-sticky {
  background-color: rgba(255, 255, 255, 0.7);
}

.is-position-prelative {
  position: relative;
}

.info-highlight::before {
  content: '\f0eb';
  font-family: 'Font Awesome 6 Free';
  padding-right: 0.5rem;
}

.has-bold-text {
  font-weight: $font-weight-bold;
}

.is-uppercase {
  text-transform: uppercase;
}

.is-color-primary {
  color: $color-primary !important;
}

.is-color-white {
  color: $color-white !important;
}

.is-color-black {
  color: $color-black !important;
}

.background-primary {
  background-color: $color-primary;
}

.background-blue-1 {
  background-color: $color-light-shade-1;
}

.background-grey-1 {
  background-color: $color-light-shade-3;
}

.background-grey-1 {
  background-color: $color-light-shade-3;
}

.background-grey-2 {
  background-color: $color-light-shade-2;
}

.background-dark-shade-2 {
  background-color: $color-dark-shade-2;
}

a {
  color: $color-black;
  transition: color 0.25s ease-in-out;

  &:hover {
    transition: color 0.25s ease-in-out;
    color: $link-hover-color;
  }
}

.icon {
  &-lock::before {
    content: '\f023';
    font-family: 'Font Awesome 6 Free';
    font-size: 1rem;
    margin-right: 0.5rem;
    font-weight: 900;
  }

  &-tag::before {
    content: '\f02b';
    font-family: 'Font Awesome 6 Free';
    font-size: 1rem;
    margin-right: 0.5rem;
    font-weight: 900;
  }

  &-contact::before {
    content: url(../../../images/icon-footer-kontakt.svg);
    font-size: 1rem;
    margin-right: 0.5rem;
    font-weight: 900;
  }
  &-faq::before {
    content: url(../../../images/icon-footer-faq.svg);
    font-size: 1rem;
    margin-right: 0.5rem;
    font-weight: 900;
  }

  &-newspaper::before {
    content: '\f1ea';
    font-family: 'Font Awesome 6 Free';
    font-size: 1rem;
    margin-right: 0.5rem;
    font-weight: 900;
  }

  &-graduation-cap::before {
    content: '\f19d';
    font-family: 'Font Awesome 6 Free';
    font-size: 1rem;
    margin-right: 0.5rem;
    font-weight: 900;
  }

  &-1::before {
    content: "1";
    font-family: $font-primary;
    font-size: 1rem;
    font-weight: 900;
  }

  &-2::before {
    content: "2";
    font-family: $font-primary;
    font-size: 1rem;
    font-weight: 900;
  }

  &-3::before {
    content: "3";
    font-family: $font-primary;
    font-size: 1rem;
    font-weight: 900;
  }

  &-4::before {
    content: "4";
    font-family: $font-primary;
    font-size: 1rem;
    font-weight: 900;
  }

  &-5::before {
    content: "5";
    font-family: $font-primary;
    font-size: 1rem;
    font-weight: 900;
  }
}

.icon-lock.is-3::before {
  font-size: 3rem;
}

.has-primary-arrow-after::after {
  content: '\f054';
  font-family: 'Font Awesome 6 Free';
  text-rendering: auto;
  font-size: 0.7rem;
  color: $color-primary;
  margin: 0 0.5rem 0 0.5rem;
  font-weight: 900;
}

.editmode-text-color {
  color: $color-primary !important;
}

@include from($tablet) {

  .slash-before-tablet {
    &::before {
      color: $color-white;
      content: '|';
      display: inline-block;
      font-size: 1rem;
      padding: 0 0.5rem;
      -webkit-transform: rotate(34deg);
      transform: rotate(34deg);
      font-weight: 600;
    }
  }

  .icon-lock.is-6-tablet::before {
    font-size: 6rem;
  }
}

@include until($tablet) {
  .mt-4-touch {
    margin-top: 1rem;
  }

  .mt-2-touch {
    margin-top: 0.5rem;
  }
}

.body {
  overflow-x: hidden;
}

.page-container {
  @extend .container;
  border-left: 1px solid $color-light-shade-3;
  border-right: 1px solid $color-light-shade-3;
}

.brand-search {
  @include until($tablet) {
    //height: 4rem !important;

    &::after {
      top: 2rem !important;
    }
  }
}

.editmode {
  .section {
    &.is-hidden-desktop {
      display: block !important;
    }
  }
}

.media {
  align-items: center;

  .image {
    width: 48px;
  }
}

.tippy-content {
  a {
    text-decoration: underline;
  }
}

.customer-service {
  background-color: $color-light-shade-1;

  .image {
    img {
      width: 48px;
    }
  }

  .heading-4 {
    color: $color-black;
  }

  .link-block-text,
  .link-block-link {
    font-weight: $font-weight-bold;
    margin-bottom: 0.25rem;
  }
}

.my-7 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.my-8 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

@include until($tablet) {
  .has-text-centered-touch {
    text-align: center !important;
  }

  .has-text-right-touch {
    text-align: right !important;
  }

  .is-mobile-justify-content-flex-start {
    justify-content: flex-start !important;
  }

  .is-mobile-justify-content-center {
    justify-content: center !important;
  }

  .is-mobile-justify-content-flex-end {
    justify-content: flex-end !important;
  }

  .is-mobile-align-items-flex-start {
    align-items: flex-start !important;
  }

  .is-mobile-align-items-flex-center {
    align-items: center !important;
  }

  .is-mobile-align-items-flex-end {
    align-items: flex-end !important;
  }
}
