
.button {
  font-weight: $font-weight-regular;
  text-transform: unset;
  text-transform: uppercase;
  transition: all .2s ease-out 0s;

  &.is-primary {
    background-color: rgba($color-white, 0.4);
    color: $button-secondary-color;
    border: 1px solid $color-primary;

    &:hover,
    &:focus {
      background-color: $color-primary;
      color: $color-white;
    }
  }

  &.is-secondary {
    background-color: $button-secondary-background;
    border: 1px solid $color-white;
    color: $color-white;

    &:hover,
    &:focus {
      background-color: $color-white;
      border: 1px solid $color-white;
      color: $color-primary;
    }
  }

  &.is-tertiary {
    background-color: rgba($color-white, 0.8);
    color: $color-secondary;
    border: 1px solid $color-secondary;

    &:hover,
    &:focus {
      background-color: $color-white;
      color: $color-secondary;
      border: 1px solid $color-white;
    }
  }

  &.is-link {
    position: relative;
    background-color: transparent;
    border: none;
    color: $color-dark-shade-2;
    text-decoration: none;
    text-transform: none;
    box-shadow: none;
    padding: 0;

    &:hover {
      background-color: transparent;
      color: $color-dark-shade-2;
    }

    &::after {
      margin-left: 0.5rem;
      font-weight: $font-weight-bold;
      font-family: 'Font Awesome 6 Free';
      content: "#{$fa-var-circle-right}"
    }
  }
}


.navbar-start .button {
  background-color: $button-secondary-background;
  border: 1px solid $color-white;
  color: white;
  text-transform: none;

  &:hover {
    background-color: $color-white;
    border: 1px solid $color-white;
    color: $color-primary;
  }
}
