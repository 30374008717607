.button {
  border-radius: 0;
  color: $button-primary-color;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  text-decoration: none;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
  white-space: normal;
  height: auto;

  &.is-secondary {
    background: $button-secondary-background;
    color: $button-secondary-color;
    border: none;

    &::after {
      color: $button-secondary-after-color;
    }
  }

  &::after {
    transition: transform 0.25s ease-out;
    content: $button-primary-after-content;
    font-family: 'Font Awesome 6 Free';
    text-rendering: auto;
    font-size: 0.9em;
    color: inherit;
    margin: 0 0.5rem 0 1rem;
    transform: translateX(0);
  }

  &:hover {
    &::after {
      transform: translateX(0.5rem);
    }
  }

  &.is-arrowless::after {
    content: none;
  }
}

.button-container {
  display: inline-block;

  &.is-right-aligned {
    display: flex;
    justify-content: flex-end;
  }
}
