.modal-content {

  background-color: $color-white;

  @include tablet-only() {
    width: 90%;
  }

  @include from($desktop) {
    width: $desktop - $container-offset;
  }

  @include from($widescreen) {
    width: min($widescreen, $container-max-width) - $container-offset;
  }

  @include from($fullhd) {
    width: min($fullhd, $container-max-width) - $container-offset;
  }

  padding: 2rem 2rem 4rem;
  overflow-x: hidden;

  .is-full {
    position: relative;
    margin-bottom: -4rem;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -10rem;
      width: 10rem;
      height: 100%;
      background: $color-light-shade-1;
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: -10rem;
      width: 10rem;
      height: 100%;
      background: $color-light-shade-1;
    }
  }

  &.is-small {
    @include from($desktop) {
      width: 40%;
    }
  }

  /**
   * Selector modal window. It has some custom changes from
   * It styles the typically navi dropdown menu links
   */
  .selector-modal & {
    @include from($desktop) {
      width: 40%;
    }

    padding: 1rem 2rem 2rem;

    .modal-headline {
      margin-top: 0.5rem;
      margin-bottom: 1.25rem;
      min-height: 1rem;
      padding: 0.9375rem;
      background: $color-dark-shade-2;
      color: $color-white;
    }

    .button-container {
      width: 100%;

      .button {
        width: 100%;
        padding: 0.9375rem 1.125rem;
        text-transform: none;
        font-weight: $font-weight-bold;

        .fa-stack {
          margin-right: 5px;
        }

        &:hover,
        &:focus {
          .fa-inverse {
            color: $color-primary;
          }
        }
      }
    }

    .modal-close {
      position: absolute;
      align-self: flex-end;
      right: 2.5rem;
      top: 2.1rem;

      &::before, &::after {
        background-color: $color-white;
      }
    }
  }
}

.modal-close {
  position: absolute;

  &::before,
  &::after {
    background-color: $color-black;
  }
}
