.section {
  &.is-small {
    padding: 1rem 1rem 1.5rem;
  }

  @include from($tablet) {
    &.grid-2 {
      > .columns,
      > .container > .columns {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &.grid-3 {
      > .columns,
      > .container > .columns {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
      }
    }

    &.grid-4 {
      > .columns,
      > .container > .columns {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
      }
    }

    &.grid-5 {
      > .columns,
      > .container > .columns {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
      }
    }
  }
}
