.navbar {
  &-menu {
    padding: $navbar-padding;
    background-color: $navbar-background-color;
  }

  .container {
    border: 1px solid $color-light-shade-3;
    border-top-width: 0;
  }

  .button {
    vertical-align: text-bottom;
    margin-left: 0.5rem;
  }

  &-burger {
    width: 2.5rem;
    height: 2.5rem;
    display: inline-block;
    margin-left: unset;
    vertical-align: text-bottom;
    color: $color-black;

    span {
      height: 3px;
      width: 26px;
      left: calc(50% - 12px);
    }

    &:hover {
      color: $color-black;
      background-color: transparent;
    }
  }

  .breadcrumb {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: unset;
    }
  }

  &-item {
    text-decoration: none;
    box-shadow: 0 0 $navbar-item-underline-color;
    transition: box-shadow 0.25s ease-out;

    &.is-active {
      font-weight: $font-weight-bold;
      box-shadow: 0 4px $navbar-item-underline-color;
    }

    &:hover {
      transition: box-shadow 0.25s ease-out;
      box-shadow: 0 4px $navbar-item-underline-color;
    }
  }

  &-brand {
    .navbar-item {
      &:hover {
        box-shadow: none;
      }
    }
  }

  &-submenu {
    ul,
    li {
      display: inline-block;
    }

    &-container {
      background-color: $color-light-shade-1;
    }

    &-title {
      position: relative;
      background-color: $color-white;
      display: inline-block;
      padding: 0.4rem 1rem 0.4rem 0.5rem;
      clip-path: polygon(0% 0%, 100% 0%, calc(100% - 12px) 100%, 0% 100%);
      overflow: visible;
    }

    &-link {
      display: inline-block;
      text-decoration: none;
      font-weight: $font-weight-light;
      margin: 0 0.8rem;
      transition: box-shadow 0.25s ease-out;
      box-shadow: 0 0 $navbar-item-underline-color;

      &.is-active {
        font-weight: $font-weight-bold;
        transition: box-shadow 0.25s ease-out;
        box-shadow: 0 4px $navbar-item-underline-color;
      }

      &:hover {
        transition: box-shadow 0.25s ease-out;
        box-shadow: 0 4px $navbar-item-underline-color;
      }
    }
  }
}

.navbar-submenu.is-sticky {
  .navbar-submenu-title {
    background-color: $color-primary;
    transition: background-color 0.5s ease;
  }
}
