.offcanvas {

  &-menu {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    width: 0;
    z-index: 100;
    background-color: $color-primary;
    transition: width 0.25s ease-in-out;
    overflow: hidden;

    .button {
      &.is-primary {
        background-color: $color-primary;
        border-color: $color-white;
        color: $color-white;
        transition: all 0.3s ease-out;
        text-transform: none;

        &:hover {
          background-color: $color-white;
          color: $color-primary;
        }
      }
    }

    &.is-active {
      transition: width 0.25s ease-in-out;
      width: 100%;
      overflow: scoll;
    }

    .offcanvas-menu-link {
      text-decoration: none;
      text-transform: none;
      color: $color-white;

      &.is-active {
        color: $color-secondary;
        i {
          color: $color-secondary;
        }
      }
    }

    &-container {
      height: 100%;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
    }
  }

  &-submenu-link {
    font-size: 1rem;
    text-align: center;
    text-decoration: none;

    &.is-active {
      color: $color-primary;
    }
  }

  &-close-icon {
    display: inline-block;
    position: absolute;
    top: 2rem;
    right: 2rem;
    cursor: pointer;
  }
}
