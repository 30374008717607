.tiles {
  background-color: $color-white;

  .tile:has(.tile-visual) {
    min-height: 250px;
  }

  .tile.is-right:has(.tile-visual) {
    @include until($tablet) {
      order: 1;
    }
  }

  .tile.is-left:has(.tile-text) {
    @include until($tablet) {
      order: 2;
    }
  }

  .columns {
    display: flex;
    align-items: stretch;

    @include until($tablet) {
      flex-direction: column;
    }

    &.is-gapless {
      margin-bottom: unset;
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .tile-visual {
    height: 100%;

    picture {
      display: block;
      height: 100%;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
}
