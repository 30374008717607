.one-column {
  > .column {
    display: flex;

    &.no-flex {
      display: block;
    }

    &.text-flow-none {
      line-height: 1;
    }
  }
}