.faq-navigation {
  border-right: 1px solid $color-light-shade-1;
  font-weight: $font-weight-bold;

  li + li {
    margin-top: 0.75rem;
  }

  .faq-category {
    line-height: 1.5;
    padding-bottom: 3px;

    &.is-active {
      font-weight: $font-weight-bold;
      color: $color-primary;
      border-bottom: 4px solid $color-primary;
    }
  }
}
