.rate-box {
  display: flex;
  margin-top: 4%;
  text-align: left;

  h1,h2,h3,h4,h5,h6,strong {
    color: $color-white;
  }

  &.is-primary-schema {
    h1,h2,h3,h4,h5,h6,strong {
      color: $color-primary;
    }
  }

  &.is-editmode {
    h1,h2,h3,h4,h5,h6,strong {
      color: $color-black !important;
    }
  }

  &--title {
    [class^="heading"] {
      font-size: $slider-rate-box-font-size;
      line-height: 1;
      letter-spacing: 1px;
    }
  }

  &--condition {
    display: flex;
    position: relative;
    align-items: center;

    [class^="heading"] {
      position: relative;
      font-size: calc($slider-rate-box-font-size * 2.333);
      line-height: 0.7;
      letter-spacing: 4px;

      sup {
        position: absolute;
        top: -5px;
        margin-left: 2%;
        font-size: $slider-rate-box-font-size;
        font-weight: $font-weight-light;
      }
    }
  }
}

@include until($tablet) {
  .rate-box {
    &--title {
      [class^="heading"] {
        font-size: $slider-mobile-rate-box-font-size;
      }
    }

    &--condition {
      [class^="heading"] {
        font-size: calc($slider-mobile-rate-box-font-size * 2.333);

        sup {
          font-size: $slider-mobile-rate-box-font-size;
        }
      }
    }
  }
}