.hero {
  &.is-medium {
    .hero-body {
      min-height: 50vh;
    }
  }
}

.hero-body {
  display: flex;
  position: relative;

  .columns {
    width: 100%;
  }

  @include until($tablet) {
    flex-direction: column;
    justify-content: flex-end;
  }
}

.hero-image {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
  }

  + .columns {
    position: relative;
  }

  &.has-dropshadow:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  }
}

.hero-headline {
  color: $color-white;
  padding: 1.5rem 2rem;
  background-color: rgba($color-dark-shade-2, 0.8);
  display: flex;
  align-items: center;
  gap: 2rem;
  @include until($tablet) {
    flex-direction: column;
  }

  .heading-1 {
    color: $color-white;
    font-weight: $font-weight-regular;
  }
}

.hero-button {
  @include from($tablet) {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
  }
}

.hero-product-teaser {
  margin-top: -4rem;
}

.editmode {
  .container {
    margin: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}
