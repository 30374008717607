.search {
  input {
    &::placeholder {
      color: $color-light-shade-4;
    }

    &::-ms-input-placeholder {
      color: $color-light-shade-4;
    }
  }

  &.in-page {
    .control {
      &.has-icons-left,
      &.has-icons-right {
        input {
          font-weight: $font-weight-bold;
          border: 1px solid $color-light-shade-5;
          padding: 12px 12px 12px 40px;
        }

        .icon,
        .icon:focus {
          color: $color-primary;
        }
      }
    }
  }
}