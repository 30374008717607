.colored-table {
  &-container {
    overflow-x: auto;
  }

  width: 100%;
  border-collapse: separate;
  border-spacing: 3px 1px;

  th,
  td {
    padding: 1rem;
  }

  td:nth-child(2),
  th:nth-child(2) {
    background-color: $color-primary;
    color: $color-white;
  }

  td:nth-child(3),
  th:nth-child(3) {
    background-color: $color-light-shade-3;
  }

  td:nth-child(n + 4),
  th:nth-child(n + 4) {
    background-color: $color-light-shade-2;
  }

  td.has-no-background {
    background-color: transparent;
  }
}

.one-third-table {
  overflow: auto;

  .right-column {
    min-width: 550px;
  }
}

table {
  div,
  img {
    margin-bottom: 0 !important;
  }

  &.is-vcentered {
    td {
      vertical-align: middle !important;

    }
  }
}
