//raw video
.video-wrapper {
  position: relative;
  width: fit-content;

  &.is-contained {
    iframe {
      width: 100%;
      transform: unset;
    }
  }

  &.is-raw-youtube,
  &.is-raw-asset {
    width: 100%;
    height: 100%;

    .editable-wrapper,
    .pimcore_editable_video {
      height: 100%;
    }

    iframe {
      width: 100%;
    }

    video {
      max-height: 400px;
    }
  }
}

@include from($tablet) {
  .video-wrapper {
    &.is-raw-youtube,
    &.is-raw-asset {
      iframe {
        height: 100%;
        min-height: 250px;
      }
    }
  }
}
