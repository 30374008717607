.field-label {
  text-align: left;

  .label {
    font-weight: $font-weight-regular;
  }

  @include from($tablet) {
    flex-grow: 2;
  }
}

form {
  .box {
    background-color: $color-light-shade-1;
  }

  .help {
    font-weight: $font-weight-bold;
  }

  select select, .textarea, .input {
    border-radius: 0;
  }

  .has-floating-label {
    .label {
      font-size: 0.75rem;
      padding-left: 0.5rem;
      transform: translateY(120%);
      transition: transform 0.3s ease-out;
    }

    &.is-active {
      .label {
        transform: translateY(0);
      }
    }
  }
}
