.text-link,
.wysiwyg {
  a {
    font-weight: $font-weight-bold;
    text-decoration: underline;
    color: $color-black;
    transition: color 0.25s ease-in-out;

    &:hover {
      transition: color 0.25s ease-in-out;
      color: $link-hover-color;
    }
  }
}

.textlink.is-secondary {
  color: $color-white;
  text-transform: uppercase;
  font-weight: $font-weight-bold;
  transition: color 0.25s ease;

  &:hover {
    transition: color 0.25s ease;
    color: $color-primary;
  }
}

.wysiwyg {
  icon + * {
    display: inline;
  }

  ul li:not(:first-child) {
    margin-top: 1rem;
  }

  ul li {
    list-style: none;
    position: relative;
    padding-left: 2em;
  }

  ul li:before {
    content: '\f00c';
    font-family: 'Font Awesome 6 Free';
    position: absolute;
    left: 0;
    width: 2em;
    text-align: center;
    font-weight: 900;
  }

  &.has-primary-ul ul li:before {
    color: $color-primary;
  }

  &.has-squeezed-ul ul li:not(:first-child) {
    margin-top: 0.5rem;
  }
}

