$font-path: '../fonts/encode-sans';

/* encode-sans-300 */
@font-face {
  font-display: swap;
  font-family: 'Encode Sans';
  font-style: normal;
  font-weight: 300;
  font-stretch: 100%;
  src: url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-300.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-300.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-300.svg#EncodeSans') format('svg'); /* Legacy iOS */
}
/* encode-sans-400 */
@font-face {
  font-display: swap;
  font-family: 'Encode Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: 100%;
  src: url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-regular.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-regular.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-regular.svg#EncodeSans') format('svg'); /* Legacy iOS */
}
/* encode-sans-500 */
@font-face {
  font-display: swap;
  font-family: 'Encode Sans';
  font-style: normal;
  font-stretch: 100%;
  font-weight: 500;
  src: url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-500.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-500.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-500.svg#EncodeSans') format('svg'); /* Legacy iOS */
}
/* encode-sans-700 */
@font-face {
  font-display: swap;
  font-family: 'Encode Sans';
  font-style: normal;
  font-stretch: 100%;
  font-weight: 700;
  src: url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-700.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-700.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/encode-sans-v19-latin_latin-ext_vietnamese-700.svg#EncodeSans') format('svg'); /* Legacy iOS */
}
/* encode-sans-expanded-300 */
@font-face {
  font-display: swap;
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 300;
  src: url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-300.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-300.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-300.svg#EncodeSansExpanded') format('svg'); /* Legacy iOS */
}
/* encode-sans-expanded-400 */
@font-face {
  font-display: swap;
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-regular.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-regular.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-regular.svg#EncodeSansExpanded') format('svg'); /* Legacy iOS */
}
/* encode-sans-expanded-500 */
@font-face {
  font-display: swap;
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-500.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-500.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-500.svg#EncodeSansExpanded') format('svg'); /* Legacy iOS */
}
/* encode-sans-expanded-700 */
@font-face {
  font-display: swap;
  font-family: 'Encode Sans Expanded';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-700.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-700.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/encode-sans-expanded-v10-latin_latin-ext_vietnamese-700.svg#EncodeSansExpanded') format('svg'); /* Legacy iOS */
}
/* encode-sans-condensed-300 */
@font-face {
  font-display: swap;
  font-family: 'Encode Sans Condensed';
  font-style: normal;
  font-weight: 300;
  src: url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-300.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-300.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-300.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-300.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-300.svg#EncodeSansCondensed') format('svg'); /* Legacy iOS */
}
/* encode-sans-condensed-400 */
@font-face {
  font-display: swap;
  font-family: 'Encode Sans Condensed';
  font-style: normal;
  font-weight: 400;
  src: url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-regular.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-regular.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-regular.svg#EncodeSansCondensed') format('svg'); /* Legacy iOS */
}
/* encode-sans-condensed-500 */
@font-face {
  font-display: swap;
  font-family: 'Encode Sans Condensed';
  font-style: normal;
  font-weight: 500;
  src: url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-500.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-500.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-500.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-500.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-500.svg#EncodeSansCondensed') format('svg'); /* Legacy iOS */
}
/* encode-sans-condensed-700 */
@font-face {
  font-display: swap;
  font-family: 'Encode Sans Condensed';
  font-style: normal;
  font-weight: 700;
  src: url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-700.eot'); /* IE9 Compat Modes */
  src: url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-700.woff') format('woff'), /* Modern Browsers */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('#{$font-path}/encode-sans-condensed-v10-latin_latin-ext_vietnamese-700.svg#EncodeSansCondensed') format('svg'); /* Legacy iOS */
}
