.slider {
  &-tab-container {
    background-color: $slider-tab-background-color;
    color: $slider-tab-color;
  }

  &-tab {
    cursor: pointer;

    &.is-active {
      background-color: $color-primary;
    }

    &-container {
      .column {
        line-height: 3rem;
      }
    }
  }
}

.swiper-pagination {
  position: static;

  span {
    height: 15px;
    width: 15px;
    border: 1px solid $color-light-shade-4;
    background-color: transparent;
  }
}

span.swiper-pagination-bullet-active {
  background-color: $color-primary;
}

.swiper-button-next,
.swiper-button-prev {
  color: $color-light-shade-4;
  width: 50px;
  height: 50px;
  background-color: $color-white;
  border-radius: 50%;
  opacity: 0.7;
  &:after {
    font-size: 1.5rem;
  }
}

/* .swiper-wrapper {
  height: 500px;
} */

.swiper-slide {
  height: 500px;
}

.slider-image-wrapper {

  height: 100%;
  width: auto;
  position: relative;
  z-index: 5;

  img {
    object-fit: cover;
    height: 100%;
  }

  .slider-content {
    position: absolute;
    padding: 1rem;
    z-index: 5;
    top:0;
    left:0;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
