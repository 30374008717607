.teaser {
  &-content {
    background: transparent;

    &.has-primary-background {
      background: $color-primary !important;
      color: $color-white;

      strong {
        color: $color-white;
      }
    }

    &.has-secondary-background {
      background: $color-dark-shade-1 !important;
      color: $color-white;

      strong {
        color: $color-white;
      }
    }

    &.has-tertiary-background {
      background: $color-dark-shade-5 !important;
      color: $color-white;

      strong {
        color: $color-white;
      }
    }
  }

  &.is-image-text {
    .teaser-content {
      padding: 1.5rem;

      .heading-1,
      .heading-2,
      .heading-3,
      .heading-4,
      .heading-5 {
        color: inherit;
      }

      ul {
        li {
          margin-top: 0.2rem;
        }
      }

      &.has-btn-left {
        .button-container {
          @extend .has-text-left;
        }
      }

      &.has-btn-centered {
        .button-container {
          @extend .has-text-centered;
        }
      }

      &.has-btn-right {
        .button-container {
          @extend .has-text-right;
        }
      }
    }

    .button {
      font-size: 0.83rem;
    }

    .teaser-fullwidth-fix-height {
      height: 300px;

      img {
        object-position: center right;
      }
    }
  }

  &.is-headline-link,
  &.is-text-link {
    [class^="heading"],
    strong {
      color: $color-white;
    }

    .heading-5 {
      font-size: 1rem;
    }

    .heading-3 {
      font-size: 1.22rem;
    }

    .button {
      font-size: 0.83rem;
    }

    &.editmode {
      color: $color-black;
    }
  }

  &.has-progress {
    .teaser-content {
      position: relative;
      z-index: 3;
      background: $color-white;

      &::before {
        content: '';
        position: absolute;
        background: linear-gradient(0deg, $color-white 0%, $color-white 30%, rgba(255,255,255,0) 100%);
        z-index: -1;
        height: 60%;
        top: -45%;
        left: 0;
        right: 0;
      }

      &.has-primary-background {
        background: $color-primary;

        &::before {
          background: linear-gradient(0deg, $color-primary 0%, $color-primary 30%, rgba(36,55,130,0) 100%);
        }
      }

      &.has-secondary-background {
        background: $color-dark-shade-1;

        &::before {
          background: linear-gradient(0deg, $color-dark-shade-1 0%, $color-dark-shade-1 30%, rgba(58,58,58,0) 100%);
        }
      }

      &.has-tertiary-background {
        background: $color-dark-shade-5;

        &::before {
          background: linear-gradient(0deg, $color-dark-shade-5 0%, $color-dark-shade-5 30%, rgba(30,35,54,0) 100%);
        }
      }
    }
  }
}
