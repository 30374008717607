.footer {
  a {
    &:hover,
    &:focus {
      color: $color-light-shade-5;
    }
  }
}

.footer-links {
  .columns {
    justify-content: center;

    .column {
      flex-grow: 0;
    }
  }

  .column {
    &:last-child {
      .footer-link::after {
        display: none;
      }
    }
  }

  .footer-link {
    white-space: nowrap;
    font-weight: 500;
    font-size: 17px;
    color: $color-white;

    &::before {
      font-size: 1em;
    }
  }

}

.footer-legal {
  justify-content: center;
  font-size: $small-font-size;
  font-weight: 500;

  a,
  span {
    &::before {
      padding-left: 0;
    }
  }
}

.footer-search {
  .input {
    background-color: transparent;
    color: $color-white;

    &::placeholder {
      color: $color-white;
    }

    &::-ms-input-placeholder {
      color: $color-white;
    }
  }

  .control {
    &.has-icons-left,
    &.has-icons-right {
      .input:focus ~ .icon {
          color: $color-white;
      }
    }
  }
}

.footer-footnote {
  padding: 2rem;
  border-top: 1px solid $color-dark-shade-1;
  font-size: 0.8333rem;
  font-weight: $font-weight-regular;

  strong,
  a {
    color: $color-white;
  }

  a {
    font-weight: $font-weight-regular;
  }

  table {
    margin-left: 1em;
  }
}

@include until($tablet) {
  .footer-legal {
    gap: 1em;
  }

  .footer-footnote {
    padding: 1rem;

    table {
      tr {
        td {
          &:first-child {
            padding-right: 1rem;
          }
        }
      }
    }
  }
}

@include from($tablet) {
  .container.is-sticky {
    .footer {
      background: $color-black;
      opacity: 0.75;
    }
  }
}
