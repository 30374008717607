.list-element {
  padding: 0.55rem 0 0 0;

  li {
    position: relative;
    margin-bottom: 0.55rem;
  }

  &.has-all-around-lines {
    border-top: 1px solid $color-light-shade-5;

    li {
      border-bottom: 1px solid $color-light-shade-5;
      padding-bottom: 0.55rem;
    }
  }

  &.has-inner-lines {
    li {
      padding-bottom: 0.55rem;

      &:not(:last-child) {
        border-bottom: 1px solid $color-light-shade-5;
      }
    }
  }

  &.items-ol {
    margin-left: 0;

    li {
      padding-left: 2em;
      counter-increment: step-counter;

      &:before {
        position: absolute;
        content: counter(step-counter);
        font-weight: $font-weight-bold;
        background: $color-black;
        display: inline-block;
        border-radius: 50%;
        color: $color-white;
        top: 2px;
        left: 0;
        text-align: center;
        padding: 0;
        width: 1.5em;
        height: 1.5em;
      }
    }

    &.is-primary-bullets {
      li {
        &:before {
          background: $color-primary;
        }
      }
    }

    &.is-secondary-bullets {
      li {
        &:before {
          background: $color-secondary;
        }
      }
    }
  }

  &.items-ul {
    &.is-primary-bullets {
      li {
        .fa-li {
          color: $color-primary;
        }
      }
    }

    &.is-secondary-bullets {
      li {
        .fa-li {
          color: $color-secondary;
        }
      }
    }
  }

  &.has-points {
    list-style-type: disc;
    margin-left: 1em;
  }
}