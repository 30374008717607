.background-grey-lighter {
  background-color: $color-light-shade-6;
}

.background-grey-lighter-2 {
  background-color: $color-light-shade-7;
}

.has-primary-text {
  color: $primary;
}

.has-dark-text {
  color: $color-anthracite;
}

.min-height-less {
  min-height: unset !important;
}

.has-gap-8 {
  gap: 8px;
}

.has-gap-16 {
  gap: 16px;
}

.has-gap-24 {
  gap: 24px;
}

.is-padding-left-right-less {
  padding-left: 0;
  padding-right: 0;
}

.is-padding-top-bottom-less {
  padding-top: 0;
  padding-bottom: 0;
}

.is-padding-less {
  padding: 0;
}

@include from($tablet) {
  .slash-before-tablet {
    &::before {
      font-weight: $font-weight-light;
      -webkit-transform: none;
      transform: none;
    }

    &.has-18-deg {
      &::before {
        -webkit-transform: rotate(18deg);
        transform: rotate(18deg);
      }
    }
  }

  .slash-after-tablet {
    &::after {
      font-weight: $font-weight-light;
      -webkit-transform: none;
      transform: none;
      position: relative;
      right: -0.5em;
    }
  }
}

@include until($tablet) {
  .is-mobile-flex-column {
    flex-direction: column !important;
  }

  .is-mobile-flex-row {
    flex-direction: row !important;
  }
}
